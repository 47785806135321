.card {
  cursor: pointer;
  background-color: #f6f4f5 !important;
  border-radius: 5px;
  box-shadow: 0px 1px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12) !important;
}

.widths {
  width: 50%;

  @media screen and (max-width:767px) {
    width: 100%;
  }
}

.title {
  font-size: 18px;
  font-weight: 700;
  color: #4f5054;
}

.description {
  font-size: 14px;
  font-weight: 600;
  margin-top: 20px !important;
  color: #4b4a4a;
}

.settings {
  font-size: 22px;
  font-weight: 700;
}

.selectWidth {
  width: 50%;

  @media screen and (max-width:767px) {
    width: 100%;
  }
}

.label {
  width: 40%;
  font-size: 16px;
  font-weight: 600;

  @media screen and (max-width:767px) {
    width: 100%;
  }
}

.grid {
  margin: 5px -5px !important;
  width: 100% !important;
}

.selectIntervalTime {
  width: 70px;
  height: 30px;
}

.supplyContainer {
  max-width: 100%;
}

.categoriesList {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.categoryCard {
  background-color: white;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  padding: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.categoryHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
  padding-bottom: 10px;
  border-bottom: 1px solid #e0e0e0;
}

.categoryHeader h2 {
  margin: 0;
  color: #333;
}

.inputGroup {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 8px;
  margin-bottom: 16px;
}

.textInput {
  width: 100%;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  margin: 5px;
}

.btn {
  padding: 5px 10px;
  border: none;
  width: 100%;
  border-radius: 3px;
  font-size: 15px;
  cursor: pointer;
  display: flex;
  align-items: center;
}

.btnPrimary {
  background-color: #078037 !important;
  color: white !important;
}

.btnSuccess {
  background-color: #2196F3 !important;
  color: white !important;
}

.btnIcon {
  background: none !important;
  border: none !important;
  cursor: pointer !important;
  padding: 4px !important;
}

.cancel {
  background-color: #ef4444 !important;
  color: white !important;
}

.edit {
  color: #2196F3 !important;
  text-decoration: underline !important;
}

.delete {
  color: #ef4444 !important;
  text-decoration: underline !important;
}

.delete:hover {
  color: #dc2626 !important;
}

.itemsList {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.itemCard {
  background-color: #f9f9f9;
  border: 1px solid #e0e0e0;
  border-radius: 4px;
  padding: 15px;
}

.itemCard h3 {
  margin-top: 0;
  color: #333;
}

.itemHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.itemDescription {
  color: #666;
  margin-bottom: 10px;
}

.editBtn {
  color: #078037 !important;
  border: none !important;
  background: none !important;
  padding: 0% !important;
}

.editIcons {
  margin-left: 40px;
}

.deleteBtn {
  color: #f62d51 !important;
  border: none !important;
  background: none !important;
  padding: 0% !important
}

.itemActions {
  display: flex;
  width: 105px;
}

.orderSelect {
  display: flex;
  align-items: center;
  width: 70%;
}
/* Mobile Responsiveness */
@media (max-width: 767px) {
  .inputGroup {
    grid-template-columns: 1fr;
  }

  .categoryHeader {
    flex-direction: column;
    align-items: flex-start;
  }
}